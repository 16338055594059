import Handsontable from 'handsontable';
import { EventBus } from '@/patterns/event-bus.js';

export const partners_constants = {
  HOT_TABLE_COLUMNS: [
    {
      title: 'Панель управления',
      width: 120,
      readOnly: true,
      renderer: function(instance, td, row, col, prop, value) {
        if (td.hasAttribute('ghost-table')) return;
        Handsontable.renderers.HtmlRenderer.apply(this, arguments);
        const partnerId = instance.getCell(row, 1).textContent;

        let wrapper = document.createElement('div');
        wrapper.className = 'p-1 pb-1';
        
        let btnUpdateUser = document.createElement('a');
        btnUpdateUser.className = 'icon-handsontable-pencil';
        btnUpdateUser.innerHTML = '✎';
        btnUpdateUser.href = `${window.location.href.split('#')[0]}#/partner-update/${partnerId}`;
        btnUpdateUser.setAttribute('target', '_blank');
        wrapper.appendChild(btnUpdateUser);

        let btnCopyPartnerSettings = document.createElement('a');
        btnCopyPartnerSettings.className = 'icon-handsontable-copy-from-id ml-2';
        btnCopyPartnerSettings.innerHTML = '⎘';
        btnCopyPartnerSettings.title = 'Copy settings to a new plugin';
        btnCopyPartnerSettings.href = `${window.location.href.split('#')[0]}#/partner-create?copyFromId=${partnerId}`;
        btnCopyPartnerSettings.setAttribute('target', '_blank');
        wrapper.appendChild(btnCopyPartnerSettings);

        td.appendChild(wrapper);
      },
    },
    {
      value: 'id',
      title: 'ID',
      width: 50,
      readOnly: true
    },
    {
      value: 'name',
      title: 'Имя',
      width: 120,
      readOnly: true
    },
    {
      value: 'domains',
      title: 'Список доменов',
      width: 120,
      readOnly: true,
      renderer: function(instance, td, row, col, prop, value) {
        Handsontable.renderers.HtmlRenderer.apply(this, arguments);
        if (!value || value.length == 0) return;

        td.innerHTML = value.join(', ');
      },
    },
    {
      value: 'domains',
      title: 'Ссылка перехода',
      width: 120,
      readOnly: true,
      renderer: function(instance, td, row, col, prop, value) {
        Handsontable.renderers.HtmlRenderer.apply(this, arguments);
        if (!value || value.length == 0) return;

        td.innerHTML = `<a href="https://${value[0]}" target="_blank">Перейти на сайт</a>`;
      },
    }
  ]
};
