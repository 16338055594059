import gql from 'graphql-tag';

const PARTNER_FIELDS = `
  domains
  id
  name
`;

export const query_partners = gql`
  query query_partners(
    $cursor: String,
    $first: Int,
  ) {
    partners(
      first: $first,
      after: $cursor,
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      nodes {
        ${PARTNER_FIELDS}
      }
    }
  }
`;
