<template>
  <div class="main">
    <div class="handsontable-wrap no-style">
      <data-loader v-bind:isLoading="isLoading"
                   innerText="Подождите, данные загружаются"
                   v-bind:isSpinner="true" />
      <div>
        <div class="second-head-container p-2"
             ref="secondHead">
          <div>
            <button class="btn btn-primary btn-sm"
                    v-on:click="createPartner">Создать партнера</button>
          </div>
        </div>
        <div ref="clientsHotTable"
             class="clients-table"></div>
        <p v-if="!listPartners && !isLoading" class="no-data">Данных не найдено</p>
      </div>
    </div>
  </div>
</template>

<script>
import clients from '@/lib/data_mappers/partners/partners.js';
import DataLoader from '@/components/DataLoader.vue';
import { partners_constants } from '@/lib/handsontable/partners/table_constants.js';

export default {
  data() {
    return {
      isLoading: true,
      listPartners: null
    };
  },
  components: {
    DataLoader
  },
  computed: {
    tableOptions() {
      return partners_constants.HOT_TABLE_COLUMNS;
    }
  },
  methods: {
    updateState(payload) {
      this.isLoading = payload.isLoading;
    },
    loadPartnersData() {
      this.partnersInstance.loadPartnersData(
        {
          query: {
            first: 700
          },
          table: {
            container: this.$refs.clientsHotTable
          }
        }
      )
    },
    refreshPartnersData() {
      console.log('refresh partners data');
      
      this.loadPartnersData();
    },
    createPartner() {
      let routeData = this.$router.resolve({ name: 'PartnerCreate' });
      window.open(routeData.href, '_blank');
    }
  },
  async mounted() {
    this.$nextTick(() => {
      this.loadPartnersData();
    });

    this.partnersInstance = clients;
    this.partnersInstance.callbackStatus = this.updateState.bind(this);
    this.listPartners = this.partnersInstance.listPartners;
  }
};
</script>
