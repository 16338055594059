import { partners_constants } from '@/lib/handsontable/partners/table_constants.js';
import partners from '@/lib/data_mappers/partners/partners.js';
import Handsontable from 'handsontable';
import { registerLanguageDictionary, ruRU } from 'handsontable/i18n';
import store from '@/store';
import grid_constants from '@/assets/data/grid_constants';

registerLanguageDictionary(ruRU);

const BASE_COLUMN_WIDTH = 140;
const HEADER_HEIGHT = 51;
const TABLE_HEIGHT = window.innerHeight - HEADER_HEIGHT - 42;

export class PartnersHotTable {
  #container = null;
  #data = null;
  #hotInstance = null;
  #partnersColumns = partners_constants.HOT_TABLE_COLUMNS;
  #hasNextPage = null;
  #endCursor = null;
  #filterFields = ['name', 'domains'];

  debounceFn(colIndex, event) {
    const f = Handsontable.helper.debounce((colIndex, event) => {
      const filtersPlugin = this.#hotInstance.getPlugin('filters');

      filtersPlugin.removeConditions(colIndex);
      filtersPlugin.addCondition(colIndex, 'contains', [event.target.value]);
      filtersPlugin.filter();
    }, 100);

    return f(colIndex, event);
  }

  addEventListeners(input, colIndex) {
    input.addEventListener('keydown', event => {
      this.debounceFn(colIndex, event);
    });
  }

  getInitializedElements(colIndex) {
    const div = document.createElement('div');
    const input = document.createElement('input');
    input.className = 'form-control form-control-sm';
  
    div.className = 'filter-header';
  
    this.addEventListeners(input, colIndex);
  
    div.appendChild(input);

    return div;
  }

  addInput(col, TH) {
    if (typeof col !== 'number') {
      return col;
    }

    if (this.getFilteredColumns().includes(col) && TH.childElementCount < 2) {
      TH.appendChild(this.getInitializedElements(col));
    }
  }

  fixedColumnsLeft() {
    return document.body.clientWidth > 767 ? 1 : 0;
  }

  colWidths() {
    const arr = [];

    this.#partnersColumns.forEach((el) => {
      const objWidth = el.width || BASE_COLUMN_WIDTH;

      arr.push(objWidth);
    });

    return arr;
  }

  getFilteredColumns() {
    let arr = [];

    this.#partnersColumns.forEach((el, index) => {
      let val = el.value;

      if (this.#filterFields && this.#filterFields.indexOf(val) != -1) {
        arr.push(index);
      }
    })

    return arr;
  }

  loadMore() {
    partners.isLoading = true;
    partners.fireCallbackDone();
    const q = {
      first: 50,
      cursor: this.#endCursor
    }

    partners.getListPartners(q).then((res) => {
      if (res && !res.hasOwnProperty('error')) {
        this.#hasNextPage = res.pageInfo.hasNextPage;
        this.#endCursor = res.pageInfo.endCursor;
        this.#data = this.convertDataIntoHotTableFormat(partners.listPartners);
        this.#hotInstance.loadData(this.#data);
      }
    })
  }

  hot() {
    this.#hotInstance = new Handsontable(this.#container, {
      licenseKey: process.env.VUE_APP_HANDSONTABLE_LICENSE_KEY,
      data: this.#data,
      colHeaders: true,
      stretchH: 'all',
      height: TABLE_HEIGHT,
      width: '100vw',
      autoWrapRow: true,
      rowHeaders: true,
      language: ruRU.languageCode,
      columns: this.#partnersColumns,
      columnSorting: true,
      fixedColumnsLeft: this.fixedColumnsLeft(),
      colWidth: this.colWidths(),
      invalidCellClassName: 'myInvalidClass',
      filters: true,
      afterGetColHeader: (col, TH) => this.addInput(col, TH),
      beforeRefreshDimensions() { return false; },
      beforeOnCellMouseDown(event, coords) {
        if (coords.row === -1 && event.target.nodeName === 'INPUT') {
          event.stopImmediatePropagation();
          this.deselectCell();
        }
      },

      // afterScrollVertically: () => {
      //   const count = this.#data.length;
      //   const autoRowSizePlugin = this.#hotInstance.getPlugin('AutoRowSize');

      //   if (partners.isLoading) return;

      //   if (autoRowSizePlugin.getLastVisibleRow() === count - 1 && this.#hasNextPage) {
      //     this.loadMore();
      //   }
      // },
    });
  }

  convertDataIntoHotTableFormat(list) {
    const res = [];


    list.forEach((el) => {
      const listInner = [];
      listInner.push('');
      const columns = this.#partnersColumns;

      columns.forEach(col => {
        for (const [key, val] of Object.entries(el)) {
          let v = val;
          if (key == col.value) {
            if (col.hasOwnProperties) {
              if (!v) {
                v = null;
                listInner.push(v);
                return;
              }
              v = val[col.field];
            }
            listInner.push(v);
          }
        }
      });

      listInner.push(el.queries);

      res.push(listInner);
    });

    return res;
  }

  getNestedHeaders() {
    const nestedHeaders = [];
    const firstLine = [];
    const secondLine = [];
    let headerGroups = this.#partnersColumns;
    let collapsibleColumns = [];

    headerGroups = Array.from(partners_constants.HOT_TABLE_COLUMNS, x => x.columnGroup);

    var countGroupFields = headerGroups.reduce(function(acc, el) {
      acc[el] = (acc[el] || 0) + 1;
      return acc;
    }, {});

    headerGroups.forEach((el, i, arr) => {
      if (el == undefined) {
        firstLine.push('');
      }

      if (el) {
        if (el == arr[i - 1]) {
          return;
        } else {
          firstLine.push({
            label: el,
            startIndex: i
          })
        }
      }
    });

    firstLine.forEach(el => {
      if (el) {
        collapsibleColumns.push({
          row: '-2',
          col: el.startIndex,
          collapsible: true,
          label: el.label
        })
        return el.colspan = countGroupFields[el.label];
      }
    });

    this.sourceArr.forEach((el, i) => {
      secondLine.push(el.title);
    });

    nestedHeaders.push(firstLine);
    nestedHeaders.push(secondLine);

    return {nestedHeaders, collapsibleColumns};
  }

  init(args) {
    this.#container = args.container;
    this.#data = this.convertDataIntoHotTableFormat(args.data.nodes);
    this.#hasNextPage = args.data.pageInfo.hasNextPage;
    this.#endCursor = args.data.pageInfo.endCursor;

    this.hot();
  }
}

const partnersHotTable = new PartnersHotTable();

export default partnersHotTable;
