import * as apolloQueries from '../partners/queries.js';
import errorHandler from '@/lib/ErrorHandler';
import partnersHotTable from '@/lib/handsontable/partners/table.js';
import { apolloClient } from '@/lib/apollo';

export class PartnersDataMapper {
  #apolloClient = null;
  #listPartners = [];
  #callbackStatus = null;
  #isLoading = false;

  constructor(apolloClient) {
    this.#apolloClient = apolloClient;
  }

  get isLoading() {
    return this.#isLoading;
  }

  set isLoading(val) {
    this.#isLoading = val;
  }

  get listPartners() {
    return this.#listPartners;
  }

  get callbackStatus() {
    return this.#callbackStatus;
  }

  set callbackStatus(val) {
    this.#callbackStatus = val;
  }

  async getListPartners(args) {
    const x = await this.#apolloClient.query({
      query: apolloQueries.query_partners,
      notifyOnNetworkStatusChange: true,  
      variables: {
        first: args.first,
        cursor: args.cursor
      },
    })
    .then((data, loading) => {
      if (!loading) {
        if (data == null) {
          this.#isLoading = false;
          this.fireCallbackDone();
          return errorHandler.handleError('Не удалось загрузить список партнеров', args);
        }

        this.#isLoading = false;
        this.fireCallbackDone();

        const res = data.data.partners.nodes;
        this.#listPartners = this.#listPartners.length == 0 ? res : [...this.#listPartners, ...res];
        return data.data.partners;
      }
    })
    .catch((error) => {
      this.#isLoading = false;
      this.fireCallbackDone();
      return errorHandler.handleError(error, args);
    });

    return x;
  }

  fireCallbackDone() {
    if (!this.#callbackStatus) return;

    this.#callbackStatus({
      isLoading: this.isLoading,
    })
  }

  loadPartnersData(args) {
    this.#isLoading = true;
    this.fireCallbackDone();

    this.getListPartners(args.query).then((res) => {
      if (res && !res.hasOwnProperty('error')) {
        partnersHotTable.init({ ...args.table, data: res });
      }
    })
  }
}

const partners = new PartnersDataMapper(apolloClient);

export default partners;
